.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  background: #fff;
  padding: 20px;
  width: 18%;
  box-shadow: 0 7px 30px 0 hsla(210,7%,48%,.11);
  transition: all 0.3s linear;
}
.mainLogo {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 20px;justify-content: space-between;
}	
.mainLogo img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.sideMenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sideMenu ul li {
  text-transform: uppercase;
  color: #9ca3af;
  font-size: 16px;
  padding-top: 10px;
}
.sideMenu ul li ul {
  margin-top: 10px;
}
.sideMenu ul li ul li {
  padding: 0;
}
.sideMenu ul li ul li a {
  padding: 12px 16px;
  margin-bottom: 1px;
  display: block;
  border-radius: 6px;
  text-decoration: none;
  font-size: 16px;
  color: #374151;
  text-transform: capitalize;
  transition: all 0.2s linear;
}
.sideMenu ul li ul li a:hover {
  background: #f7f7f7;
}

.dahboardMain {
  position: relative;
  overflow: hidden scroll;
  display: flex;
  width: 100%;
}
.fulBodyPart {
  background-color: #fafbfb;
  width: 100%;
  height: 100%;
  max-height: 100%;transition: all 0.3s linear;
}

.navbar {
  position: static;
  top: 0;
  width: 100%;
  text-align: center;
}
.sideBrClose {
  background: transparent;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 25px; transition: all 0.2s linear;
}
.sideBrClose:hover {
  background: #f7f7f7;
}

.navbar_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
}
.navbar_main button {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  font-size: 16px;
  transition: all 0.2s linear;
  border: none;
  border-radius: 50%;
}
.navbar_main button:hover {
  background: #eae8e8;
}
.profiledetails ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.profiledetails ul li {
  margin-left: 5px;
}
.userName {
  width: 100% !important;
  height: 100% !important;
  padding: 5px 10px;
  border-radius: 6px !important;
}
.profiledetails ul li button img {
  width: 34px;
  border-radius: 50%;
}
.userName span {
  margin-left: 8px;
  color: #9ca3af;
}

.dropdownBox {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
}
.drpMenu {
  width: 20%;
  margin-right: 20px;
}
.drpSec {
  background: #fff;
  border-radius: 30px;
  padding: 35px 30px;
  box-shadow: 0 10px 40px 0 hsla(210,7%,48%,.11);
}
.drpMenu select {
  background: #fff;
  width: 100%;
  padding: 6px 10px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #dddd;
  color: #999;
}
.bodyPart {
  padding: 30px;
}
.pageTitle {
  margin-bottom: 30px;
}
.pageTitle p {
  color: #9ca3af;
  font-size: 16px;
  margin-bottom: 0;text-transform: capitalize;
}
.pageTitle h2 {
  font-size: 30px;
  color: #0f172a;
  font-weight: 700;
  margin-bottom: 10px;text-transform: capitalize;
}

.botmSection {
  margin-top: 50px;
}
.grpMchn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  background: #ffffff;padding: 10px;
  border-radius: 5px;border: 1px solid #e7e7e7;
}
.grpBox {
  padding: 20px 30px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 10px;
  background: #fafbfb;
  /* box-shadow: 0 5px 15px 0 hsla(210,7%,48%,.11); */
  box-shadow: 0px 5px 10px #00000033;
  text-align: center;
}
.grpBox:hover{
  box-shadow: 0px 2.5px 5px #00000033;
}
.grpBox h3 {
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 0;
}

.grpBox .row .col-md-6 {
  padding: 0 5px;
}

.grpMchn p {
  display: block;
  width: 100%;
  text-align: center;
  margin: 10px 0px;
  font-size: 16px;
  font-weight: 600;
}
.grpBox .row .col-md-6:nth-child(4) .grpMchn {
  margin: 0;
}
.grpBox .row .col-md-6:nth-child(3) .grpMchn {
  margin-bottom: 0;
}
.grpMchn img {
  width: 100%;
}
.sgrpBx img {
  width: 100%;
}
.sgrpBx {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  background: #fafbfb;
  border-radius: 10px;
  flex-wrap: wrap;
  box-shadow: 0px 5px 10px #00000033;
}
.sgrpBx p {
  display: block;
  width: 100%;
  text-align: center;
  margin: 30px 0px 0px 0px;
  font-size: 22px;
  font-weight: 700;
  border-radius: 5px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
thead {
  background: #484646;
  color: #fff;
}
tr:nth-child(odd) {
  background-color: #ebebeb;
}
tr:first-child {
  background: #484646;
  color: #fff;
  font-weight: 600;
}
.modal-dialog {
  max-width: unset !important;
  width: 70% !important;
}
.tableForm ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal-body{padding: 40px;}

.tableForm ul li {
  padding: 8px 15px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 18px;
  position: relative;
}
li.active {
  border-bottom: none !important;
  background: #fff !important;
  box-shadow: 0px 0px 9px 1px #ddd;
  border-radius: 10px 10px 0px 0px;
}
li.active:after {
  content: '';
  position: absolute;
  background: #ffffff;
  width: 104%;
  left: 0px;
  bottom: -10px;
  height: 10px;
}

.tableSec {
    background: #ffffff;
    padding: 30px;
    border-top: none;
    box-shadow: 0px 0px 9px 1px #ddd;
    border-radius: 0px 10px 10px 10px;
}
button.btn-close {
  outline: none !IMPORTANT;
  box-shadow: none !important;
}
